.color-icon-info cds-icon {
  --color: var(--cds-alias-status-info, #0072a3);
}

.color-icon-success cds-icon {
  --color: var(--cds-alias-status-success, #3c8500);
}

.color-icon-warning cds-icon {
  --color: var(--cds-alias-status-warning, #666);
}

.color-icon-danger cds-icon {
  --color: var(--cds-alias-status-danger, #c21d00);
}

.center {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.self-center {
  align-self: center;
}

.bolder {
  font-weight: bolder;
}

.left-align {
  text-align: left;
}

.flex-h-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.flex-v-center {
  display: flex;
  align-items: center;
}

.italics {
  font-style: italic;
}

.hidden {
  visibility: hidden;
}

.notification-icon cds-icon {
  --color: var(--cds-alias-status-warning, #efc006);
  width: 7px;
  margin-left: 3px;
}

.layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
}

.kubeapps-main-container {
  display: flex;
  height: calc(100vh - 3rem);
  min-height: 1px;
  flex: 1 1 auto;
}
.kubeapps-main-container .content-area {
  flex: 1 1 auto;
  padding: 0 0.6rem;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.layout > main {
  flex: 1;
}

.deployment-form-tabs {
  margin-bottom: 1rem;
}
.deployment-form-tabs-data {
  margin-top: 0.6rem;
  overflow-x: hidden;
}
.deployment-form-control-buttons {
  margin-bottom: 0.6rem;
}
.deployment-form-control-buttons cds-button {
  margin-right: 0.6rem;
}

.filters-menu {
  padding-left: 1.2rem;
}
.filters-menu h5 {
  font-size: small;
  font-weight: 500;
  text-transform: uppercase;
}
.filters-menu h5 cds-button {
  padding-top: 10px;
  margin-top: -10px;
  outline: 0;
}
.filters-menu .filter-section {
  margin: 0.7rem 0 0.5rem;
  font-weight: 500;
}

.section-not-found {
  display: grid;
  height: calc(100vh - 12rem);
  justify-content: center;
  margin-top: 1.8rem;
  text-align: center;
}
.section-not-found .section-description {
  text-align: left;
}
.section-not-found img {
  max-width: 400px;
}

.terminal-wrapper {
  background-color: var(--cds-global-typography-color-400, #333);
  color: var(--cds-global-typography-color-100, #fff);
}
.terminal-wrapper .terminal-code {
  padding: 0.6rem;
  overflow-x: scroll;
  text-align: left;
  white-space: pre-wrap;
}

.application-readme pre {
  overflow: auto;
  padding: 0.6rem;
  border-radius: 5px;
  margin: 0.625em 0;
  background-color: var(--cds-global-typography-color-400, #333);
  color: var(--cds-global-typography-color-100, #fff);
}
.application-readme ul {
  margin-top: 0.6rem;
}
.application-readme ul li p {
  display: inline;
}
.application-readme blockquote {
  max-width: 100%;
  padding: 0.1em 1em;
  border: 1px solid var(--cds-global-typography-color-200, #e8e8e8);
  border-radius: 5px;
  margin: 1em 0;
  background-color: var(--cds-global-typography-color-100, #fff);
}
.application-readme blockquote p:first-child {
  margin-top: 0.6rem;
}
.application-readme blockquote p:last-child {
  margin-bottom: 0.6rem;
}
.application-readme blockquote::before,
.application-readme blockquote::after {
  background-image: none;
}
.application-readme img {
  max-width: 100%;
}

.application-notes {
  display: grid;
  padding: 0.6rem;
  border: 2px solid var(--cds-alias-object-border-color, #f1f1f1);
  margin: 0.625em 0;
  font-family: monospace;
}
.application-notes pre {
  overflow: auto;
  padding: 0.6rem;
  border-radius: 5px;
  margin: 0.625em 0;
  background-color: var(--cds-global-typography-color-400, #333);
  color: var(--cds-global-typography-color-100, #fff);
}
.application-notes ul {
  margin-top: 0.6rem;
}
.application-notes ul li p {
  display: inline;
}
.application-notes blockquote {
  max-width: 100%;
  padding: 0.1em 1em;
  border: 1px solid var(--cds-global-typography-color-200, #e8e8e8);
  border-radius: 5px;
  margin: 1em 0;
  background-color: var(--cds-global-typography-color-100, #fff);
}
.application-notes blockquote p:first-child {
  margin-top: 0.6rem;
}
.application-notes blockquote p:last-child {
  margin-bottom: 0.6rem;
}
.application-notes blockquote::before,
.application-notes blockquote::after {
  background-image: none;
}
.application-notes img {
  max-width: 100%;
}
.application-notes p:first-child {
  margin-top: 0;
}

.after-readme-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 1.2rem 0.6rem 1.2rem 0;
}

.appview-separator {
  padding-bottom: 1.2rem;
  border-bottom: 2px solid var(--cds-alias-object-border-color, #f1f1f1);
}

.appview-first-row {
  display: flex;
}
.appview-first-row section {
  margin-right: 2rem;
}

.section-title {
  font-weight: 600;
}

.filter-summary {
  padding: 1.2rem 0 0 1.2rem;
}
.filter-summary cds-icon {
  cursor: pointer;
}

.margin-b-sm {
  margin-bottom: 0.3rem;
}

.margin-t-sm {
  margin-top: 0.3rem;
}

.margin-t-md {
  margin-top: 0.6rem;
}

.margin-t-lg {
  margin-top: 0.9rem;
}

.margin-t-xl {
  margin-top: 1.2rem;
}

.margin-t-xxl {
  margin-top: 1.9rem;
}

.clr-control-container {
  width: 100%;
  margin-top: 0.3rem;
}
.clr-control-container .clr-input-wrapper {
  max-width: 100%;
}
.clr-control-container .clr-input-wrapper .clr-input {
  width: 100%;
}
.clr-control-container textarea {
  width: 100%;
}

.flat-btn[disabled] {
  --cds-token-color-neutral-200: transparent;
  --border-width: 0;
}
